import * as React from "react"
import Footer from '../components/footer'
import "../css/service.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import NavBar from "../components/navbar"
export default class About extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  render() {
    return (
      <div>
        <div>
          <NavBar />
        </div>
        <div className="relative w-full p-4 lg:p-0">
          <div className="service-banner container pt-40 mx-auto">
            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 box-rev">

              <div className="col-span-1 lg:flex pb-0">
                <div className="service-banner-content self-center">
                  <h1 className="service-banner-title text-4xl md:text-4xl leading-relaxed md:leading-snug lg:mb-2 font-extrabold uppercase">Program</h1>
                  <p className="service-banner-description md:text-base lg:mb-4 text-gray-600">Ensuring a Safe Physical Education Program for Children
                    Fitlane Sports fosters a lively sports culture in schools which not only produces champions but also instils a sense of discipline and awareness for fitness and good health.</p>
                  <a href="/contact" className="bg-transparent mt-8 uppercase inline-block rounded shadow hover:shadow-lg py-2 px-4 btn-color">Contact Us</a>
                </div>
              </div>
              <div className="cols-span-1 pt-16 lg:pt-0">
                <img src="../medalist.jpg" className="service-banner" />
                <div className="service-bannerimage-overlay bg-yellow-400 absolute"></div>
              </div>

            </div>
          </div>
        </div>

        <section class="bg-yellow-400">
          <div className="w-full p-4 g:my-0">
            <div className="service-intro container lg:my-12 mx-auto">
              <div className="grid grid-cols-1 lg:grid-cols-5">
                <div class="service-image-section-overlay-1 bg-gray-200 p-4  mb-4 lg:mb-0 lg:m-4 lg:p-8">
                  <h3 className="lg:text-4xl text-2xl text-center font-bold lg:pb-0 font-color uppercase text-yellow-400">Student Sports Growth</h3>
                  {/* <a href="" className="bg-transparent rounded shadow hover:shadow-lg py-2 px-4 uppercase btn-color absolute mt-4">Explore Now</a> */}
                </div>
                <div className="col-span-1 lg:m-4 mb-4 lg:mb-0 relative service-intro-detail">
                  <img src="../fun1.png" className="w-full rounded-xl" />
                  <h2 className="service-title text-2xl font-extrabold lg:mt-4 uppercase text-center">Fun Kids</h2>
                  <p className="service-content text-sm text-gray-600 lg:my-4 text-center"> Here the fundamental movement of kids are checked and corrected.</p>
                </div>
                <div className="col-span-1 lg:m-4 mb-4 lg:mb-0 relative service-intro-detail">
                  <img src="../basic1.png" className="w-full rounded-xl" />
                  <h2 className="service-title text-2xl font-extrabold lg:mt-4 uppercase text-center">Basic Learners</h2>
                  <p className="service-content text-sm text-gray-600 lg:my-4 text-center"> Here the children are introduced to the basic level sports along with basic skills required and practice the exercise of deformaties.</p>
                </div>
                <div className="col-span-1 lg:m-4 mb-4 lg:mb-0 relative service-intro-detail">
                  <img src="../learn1.png" className="w-full rounded-xl" />
                  <h2 className="service-title text-2xl font-extrabold lg:mt-4 uppercase text-center">Multi Sports Skill Learners</h2>
                  <p className="service-content text-sm text-gray-600 lg:my-4 text-center"> A child here selects a sports they are talented at and practies it enough to become Professional in that sport.</p>
                </div>
                <div className="col-span-1 lg:m-4 mb-4 lg:mb-0 relative service-intro-detail">
                  <img src="../sports1.png" className="w-full rounded-xl" />
                  <h2 className="service-title text-2xl font-extrabold lg:mt-4 uppercase text-center">Sports Professionals</h2>
                  <p className="service-content text-sm text-gray-600 lg:my-4 text-center"> Here the children get the opportunity to explore their talent in their sports with the help of a professional coach.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="-z-10 p-4 mb-8 lg:mb-0">
            <div className="container mx-auto lg:py-20 relative">
              <h1 className="text-4xl md:text-4xl font-color text-center uppercase font-extrabold mb-12 lg:mb-24">Fundamental Program</h1>
              <div className="grid lg:grid-cols-2 grid-cols-1">
                <div className="col-span-1 "></div>
                <div className="col-span-1">
                  <div className="service-info">

                  </div>
                </div>
                <div className="col-span-1 lg:col-span-2 lg:ml-20 lg:mr-12">
                  <Swiper
                    breakpoints={{
                      320: {
                        width: 320,
                        slidesPerView: 1,
                      },
                      768: {
                        width: 768,
                        slidesPerView: 3,
                      }
                    }}
                    spaceBetween={15}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    <SwiperSlide>
                      <div className="lg:pt-0 h-auto p-4">
                        <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                          <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="../curriculum.png" />
                          <h2 class="text-center text-2xl font-bold uppercase">Inclusive Curriculum</h2>

                          <div class="text-center font-light text-sm">
                            <p>It is developed keeping in mind the physical needs of children and delivers complete sporting and physical education experiences.</p>
                          </div>
                        </div>
                      </div>

                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="lg:pt-0 h-auto p-4">
                        <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                          <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="../checklist.png" />
                          <h2 class="text-center text-2xl font-bold uppercase">Fitness Assessment</h2>

                          <div class="text-center font-light text-sm">
                            <p>Physical Assessment tool to track the fitness and skills of every child in the fitness sports program</p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="lg:pt-0 h-auto p-4">
                        <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                          <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="../ui.png" />
                          <h2 class="text-center text-2xl font-bold uppercase">Fitlane Sports App</h2>

                          <div class="text-center font-light text-sm">
                            <p>An initiative by Fitlane Sports to help school management, parents monitor their child fitness and sports journey in this mobile app.
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="lg:pt-0 h-auto p-4">
                        <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                          <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="../good.png" />
                          <h2 class="text-center text-2xl font-bold uppercase">Joy day</h2>

                          <div class="text-center font-light text-sm">
                            <p>Series of intra-class competitive events conducted for every class in the program.</p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="lg:pt-0 h-auto p-4">
                        <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                          <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="../trophy1.png" />
                          <h2 class="text-center text-2xl font-bold uppercase">Sports Day</h2>

                          <div class="text-center font-light text-sm">
                            <p>We provides support to schools in organising Sports Day Event
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    {/*<SwiperSlide>
                                <div className="lg:pt-0 h-auto p-4">
                                     <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                                        <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="https://avatars.githubusercontent.com/u/67946056?v=4"  />
                                        <h2 class="text-center text-2xl font-bold uppercase">Ajo Alex</h2>
                                        
                                        <div class="text-center font-light text-sm">
                                        <p>
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                        </p>
                                        </div>
                                     </div>
                                    </div>
                                </SwiperSlide>*/}
                  </Swiper>
                </div>
                <div className="service-grid-overlay-1 lg:min-height-500 h-auto absolute">

                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="-z-10 p-4 bg-gray-200">
            <div className="container mx-auto lg:py-20 relative">
              <h1 className="text-4xl md:text-4xl font-color text-center uppercase font-extrabold mb-12 lg:mb-24">Opportunities Program</h1>
              <div className="grid lg:grid-cols-2 grid-cols-1">
                <div className="col-span-1 "></div>
                <div className="col-span-1">
                  <div className="service-info">

                  </div>
                </div>
                <div className="col-span-1 lg:col-span-2 lg:ml-96 lg:pl-40 lg:mr-12">
                  <Swiper
                    breakpoints={{
                      320: {
                        width: 320,
                        slidesPerView: 1,
                      },
                      768: {
                        width: 768,
                        slidesPerView: 3,
                      }
                    }}
                    spaceBetween={15}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    <SwiperSlide>
                      <div className="lg:pt-0 h-auto p-4">
                        <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                          <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="../school.png" />
                          <h2 class="text-center text-2xl font-bold uppercase">SSL</h2>

                          <div class="text-center font-light text-sm">
                            <p>School Sports League is an intra-school competition among the students. Match-play experience for students of standard 3 and above in a league-style format. Fun games for UKG to Standard 2.
                            </p>
                          </div>
                        </div>
                      </div>

                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="lg:pt-0 h-auto p-4">
                        <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                          <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="../playtime.png" />
                          <h2 class="text-center text-2xl font-bold uppercase">Kiddies Meet</h2>

                          <div class="text-center font-light text-sm">
                            <p>Sports Meet for students of Pre-school up to standard 2.
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                    {/*<SwiperSlide>
                                <div className="lg:pt-0 h-auto p-4">
                                     <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                                        <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="https://avatars.githubusercontent.com/u/67946056?v=4"  />
                                        <h2 class="text-center text-2xl font-bold uppercase">Ajo Alex</h2>
                                        
                                        <div class="text-center font-light text-sm">
                                        <p>
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                        </p>
                                        </div>
                                     </div>
                                    </div>
                                </SwiperSlide>*/}
                    {/*<SwiperSlide>
                                <div className="lg:pt-0 h-auto p-4">
                                     <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                                        <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="https://avatars.githubusercontent.com/u/67946056?v=4"  />
                                        <h2 class="text-center text-2xl font-bold uppercase">Ajo Alex</h2>
                                        
                                        <div class="text-center font-light text-sm">
                                        <p>
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                        </p>
                                        </div>
                                     </div>
                                    </div>
                                </SwiperSlide>*/}
                    {/*<SwiperSlide>
                                <div className="lg:pt-0 h-auto p-4">
                                     <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                                        <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="https://avatars.githubusercontent.com/u/67946056?v=4"  />
                                        <h2 class="text-center text-2xl font-bold uppercase">Ajo Alex</h2>
                                        
                                        <div class="text-center font-light text-sm">
                                        <p>
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                        </p>
                                        </div>
                                     </div>
                                    </div>
                                </SwiperSlide>*/}
                    {/*<SwiperSlide>
                                <div className="lg:pt-0 h-auto p-4">
                                     <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                                        <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="https://avatars.githubusercontent.com/u/67946056?v=4"  />
                                        <h2 class="text-center text-2xl font-bold uppercase">Ajo Alex</h2>
                                        
                                        <div class="text-center font-light text-sm">
                                        <p>
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                        </p>
                                        </div>
                                     </div>
                                    </div>
                                </SwiperSlide>*/}
                  </Swiper>
                </div>
                <div className="service-grid-overlay-2 lg:min-height-500 h-auto absolute">
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="-z-10 p-4">
            <div className="container mx-auto lg:py-20 relative">
              <h1 className="text-4xl md:text-4xl font-color text-center uppercase font-extrabold mb-12 lg:mb-24">performance Program</h1>
              <div className="grid lg:grid-cols-2 grid-cols-1">
                <div className="col-span-1 "></div>
                <div className="col-span-1">
                  <div className="service-info">

                  </div>
                </div>
                <div className="col-span-1 lg:col-span-2 lg:ml-20 lg:mr-12">
                  <Swiper
                    breakpoints={{
                      320: {
                        width: 320,
                        slidesPerView: 1,
                      },
                      768: {
                        width: 768,
                        slidesPerView: 3,
                      }
                    }}
                    spaceBetween={15}
                    onSlideChange={() => console.log("slide change")}
                    onSwiper={(swiper) => console.log(swiper)}
                  >
                    <SwiperSlide>
                      <div className="lg:pt-0 h-auto p-4">
                        <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                          <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="../trophy.png" />
                          <h2 class="text-center text-2xl font-bold uppercase">FSSG</h2>

                          <div class="text-center font-light text-sm">
                            <p>Fitlane Sports School Games is an inter-school tournament featuring multiple games.
                            </p>
                          </div>
                        </div>
                      </div>

                    </SwiperSlide>
                    {/*<SwiperSlide>
                                <div className="lg:pt-0 h-auto p-4">
                                     <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                                        <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="https://avatars.githubusercontent.com/u/67946056?v=4"  />
                                        <h2 class="text-center text-2xl font-bold uppercase">Ajo Alex</h2>
                                        
                                        <div class="text-center font-light text-sm">
                                        <p>
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                        </p>
                                        </div>
                                     </div>
                                    </div>
                                </SwiperSlide>*/}
                    {/*<SwiperSlide>
                                <div className="lg:pt-0 h-auto p-4">
                                     <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                                        <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="https://avatars.githubusercontent.com/u/67946056?v=4"  />
                                        <h2 class="text-center text-2xl font-bold uppercase">Ajo Alex</h2>
                                        
                                        <div class="text-center font-light text-sm">
                                        <p>
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                        </p>
                                        </div>
                                     </div>
                                    </div>
                                </SwiperSlide>*/}
                    {/*<SwiperSlide>
                                <div className="lg:pt-0 h-auto p-4">
                                     <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                                        <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="https://avatars.githubusercontent.com/u/67946056?v=4"  />
                                        <h2 class="text-center text-2xl font-bold uppercase">Ajo Alex</h2>
                                        
                                        <div class="text-center font-light text-sm">
                                        <p>
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                        </p>
                                        </div>
                                     </div>
                                    </div>
                                </SwiperSlide>*/}
                    {/*<SwiperSlide>
                                <div className="lg:pt-0 h-auto p-4">
                                     <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                                        <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="https://avatars.githubusercontent.com/u/67946056?v=4"  />
                                        <h2 class="text-center text-2xl font-bold uppercase">Ajo Alex</h2>
                                        
                                        <div class="text-center font-light text-sm">
                                        <p>
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                        </p>
                                        </div>
                                     </div>
                                    </div>
                                </SwiperSlide>*/}
                    {/*<SwiperSlide>
                                <div className="lg:pt-0 h-auto p-4">
                                     <div class="card w-full p-4 bg-white shadow-own rounded-xl box-hei">
                                        <img class="lg:w-28 w-24 mx-auto -lg:mt-20 border-8 border-white" src="https://avatars.githubusercontent.com/u/67946056?v=4"  />
                                        <h2 class="text-center text-2xl font-bold uppercase">Ajo Alex</h2>
                                        
                                        <div class="text-center font-light text-sm">
                                        <p>
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                            Front end Developer, avid reader. Love to take a long walk, swim
                                        </p>
                                        </div>
                                     </div>
                                    </div>
                                </SwiperSlide>*/}
                  </Swiper>
                </div>
                <div className="service-grid-overlay-3 lg:min-height-500 h-auto absolute">

                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="lg:py-2 bg-gray-200">
          <div className="service-detail-section container mx-auto lg:mt-12 lg:h-3/4 lg:mb-24 p-4">
            <h3 className="text-3xl font-extrabold lg:pb-12 py-8 uppercase">Physical fitness survey among Children</h3>
            <div className="graph-img pb-9 relative">

              <div className="absolute size p-6 rounded-xl">
                <p className="text-sm text-center lg:px-4">In a recent survey, <br />
                  We conducted secondary analysis of physical fitness of children ages 6-12 years</p>

                <h3 className="my-4 text-lg lg:text-xl font-extrabold uppercase text-center font-color">2 in 5 students have unhealthy BMI</h3>

                <div className="grid grid-cols-2 lg:grid-cols-2">
                  <div className="col-span-1 lg:p-2">
                    <h3 className="text-2xl text-center font-extrabold text-yellow-400 uppercase lg:pb-2">69%</h3>
                    <p className="text-sm text-center text-gray-600">girls have healthy BMI</p>
                  </div>
                  <div className="col-span-1 lg:p-2">
                    <h3 className="text-2xl text-center font-extrabold text-yellow-400 uppercase lg:pb-2">62%</h3>
                    <p className="text-sm text-center text-gray-600">boys have healthy BMI</p>
                  </div>
                </div>

                <h3 className="my-4 lg:text-xl text-lg font-extrabold uppercase text-center font-color">The rate of unhealthy BMI of children is increasing since 2020.</h3>

                <div className="grid grid-cols-2 lg:grid-cols-2">
                  <div className="col-span-1 lg:p-2">
                    <h3 className="text-2xl text-center font-extrabold text-yellow-400 uppercase lg:pb-2">20%</h3>
                    <p className="text-sm text-center text-gray-600">2020 year</p>
                  </div>
                  <div className="col-span-1 lg:p-2">
                    <h3 className="text-2xl text-center font-extrabold text-yellow-400 uppercase lg:pb-2">33%</h3>
                    <p className="text-sm text-center text-gray-600">2021 year</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section >

        {/* <section>
                    <div className="service-image-section p-4">
                        <div className="grid grid-cols-1 mx-auto container lg:grid-cols-3 lg:py-12">
                            <div className="col-span-1 lg:p-2 pb-4 lg:pb-0 relative">
                            <div className="service-image-section-overlay bg-gray-200 p-4 lg:p-8">
                              <h3 className="text-2xl font-bold lg:pb-0 uppercase text-yellow-400">Brixhome - Real Estate Landing Page</h3>
                              <a href="#" className="bg-transparent rounded shadow hover:shadow-lg py-2 px-4 uppercase btn-color absolute mt-4">Explore Now</a>
                           </div>
                            </div>
                            <div className="col-span-1 lg:p-2 pb-4 lg:pb-0 relative">
                                <img src="../sports.jpg"  className="image-height rounded-xl"/>
                            </div>
                            <div className="col-span-1 lg:p-2">
                                <img src="../sports1.jpg"  className="rounded-xl"/>
                            </div>
                        </div>
                        
                    </div>
                </section> */}

        < section >
          <div className="product-showcase-strip py-20 bg-yellow-400">
            <div className="product-list grid grid-cols-1 lg:grid-cols-4 container mx-auto">
              <div className="showcase-layer col-span-1 pb-12 lg:pb-0 flex">
                <div className="self-center">
                  <h3 className="showcase-title text-3xl font-extrabold text-yellow-400 banner-color text-center uppercase">Our Addon Program</h3>
                </div>
              </div>

              {/* <div className="products col-span-1 p-2 self-center">
                        <img src="./cricketplayer.png" />
                        <h3 className="products-name font-bold text-xl">Cricket</h3>
                      </div>
              
                      <div className="products col-span-1 p-2 self-center">
                        <img src="./football.png" />
                        <h3 className="products-name font-bold text-xl">Football</h3>
                      </div>
            
                      <div className="products col-span-1 p-2 self-center">
                        <img src="./running.png" />
                        <h3 className="products-name font-bold text-xl">Long Jump</h3>
                      </div> */}
              <div className="self-center lg:col-span-3">

                <Swiper
                  // slidesPerView={3}
                  breakpoints={{
                    320: {
                      width: 320,
                      slidesPerView: 1,
                    },
                    768: {
                      width: 768,
                      slidesPerView: 3,
                    }
                  }}
                  spaceBetween={10}
                  onSlideChange={() => console.log("slide change")}
                  onSwiper={(swiper) => console.log(swiper)}
                >
                  <SwiperSlide>
                    <div>
                      <img src="../archery.jpg" className="lg:h-44 h-52 rounded-xl" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src="../fencing.jpg" className="lg:h-44 h-52 rounded-xl" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src="../mallakhamb.jpg" className="lg:h-44 h-52 rounded-xl" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src="../karate.jpg" className="lg:h-44 h-52 rounded-xl" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div>
                      <img src="../silambam.jpg" className="lg:h-44 h-52 rounded-xl" />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section >

        <div>
          <Footer />
        </div>
      </div >
    );
  }
}